







































































































































































































































































































































import { Component } from 'vue-property-decorator'
import { Action, Getter, State } from 'vuex-class'
import KodeStatus from '@/common/kodeStatus'
import {
  APPLY_KODE_TEST,
  GET_PROFILE_KODE_STATUS,
  SET_PROFILE_KODE_STATUS,
  UPDATE_STUDENT_STATE
} from '@/store/user/actions.type'
import { CompetenceProfileType, ProgressStep, SimpleServerResponse, UserProgressState } from '@/store/types'
import { GET_OWN_STUDENT_COMPETENCE_PROFILE } from '@/store/student/actions'
import CompetenceProfile from '@/components/kode/CompetenceProfile.vue'
import ProgressSteps from '@/components/common/ProgressSteps.vue'
import ConfirmationModal from '@/components/common/ConfirmationModal.vue'
import EventSearchResult from '@/components/search-results/EventSearchResult.vue'
import ContactForm from '@/components/forms/ContactForm.vue'
import { BOOKMARK_EVENT, GET_ALL_EVENTS_BY_COMPETENCES } from '@/store/event/actions.type'
import { Competences, Event } from '@/store/event/types'
import { hasRight } from '@/utils/rights'
import Rights from '@/common/rights'
import ResultsPage from '@/components/search-results/ResultsPage.vue'
import { KodeStatusDTO } from '@/store/kode/types'
import InfoBox from '@/components/hszg/InfoBox.vue'
import { parseDateToOutputDate } from '@/common/globals'
import MetaComponent from '@/common/MetaComponent.vue'
import moment from 'moment'

@Component({
  components: { InfoBox, ResultsPage, ContactForm, EventSearchResult, ConfirmationModal, ProgressSteps, CompetenceProfile }
})
export default class KodeMetering extends MetaComponent {
  @Getter('userStateGetter') userStateGetter: UserProgressState
  @State('showDesktopVersion') showDesktopVersion: boolean

  @Action(GET_ALL_EVENTS_BY_COMPETENCES)
  public getEventsByCompetences: (competences: Competences) => Promise<Array<Event>>

  @Action(UPDATE_STUDENT_STATE)
  public updateStudentState: (data: UserProgressState) => Promise<void>

  @Action(GET_OWN_STUDENT_COMPETENCE_PROFILE)
  public getOwnStudentCompetenceProfile: () => Promise<SimpleServerResponse>

  @Action(GET_PROFILE_KODE_STATUS)
  public getProfileKodeStatus: () => Promise<KodeStatusDTO>

  @Action(SET_PROFILE_KODE_STATUS)
  public setProfileKodeStatus: () => Promise<SimpleServerResponse>

  @Action(APPLY_KODE_TEST)
  public setApplyKodeTest: () => Promise<SimpleServerResponse>

  @Action(BOOKMARK_EVENT)
  public bookmarkEvent: (event: Event) => Promise<SimpleServerResponse>

  competenceProfile: CompetenceProfileType = {}
  loading = true

  KodeStatus = KodeStatus
  kodeTestCategories = []
  kodeDetailsSent: Date | null = null

  activeStep = 'NO_DATA'

  get bookmarkable (): boolean {
    return hasRight(Rights.BOOKMARK_EVENT)
  }

  get kodeProgress (): ProgressStep[] {
    return [
      { index: 0, status: KodeStatus.DATA, text: this.$i18n.t('kode-metering.profile-complete').toString() },
      { index: 1, status: KodeStatus.APPLY, text: this.$i18n.t('kode-metering.metering-requested').toString() },
      { index: 2, status: KodeStatus.WAITING, text: this.$i18n.t('kode-metering.metering-done').toString() },
      { index: 3, status: KodeStatus.FINISH, text: this.$i18n.t('kode-metering.results-received').toString() }
    ]
  }

  get activeTo (): number {
    const activeTo = this.kodeProgress.filter(el => el.status === this.activeStep)[0]
    if (activeTo) return activeTo.index
    else return 0
  }

  events: Event[] = []

  created (): void {
    this.loadData()
  }

  loadData () {
    this.$root.$emit('load')
    Promise.all([
      this.getProfileKodeStatus().then(data => {
        this.activeStep = data.kodeStatus
        this.kodeDetailsSent = data.kodeDetailsSent
      }),
      this.getEventsByCompetences({ topics: ['KODE_METERING'] }).then((data: Event[]) => {
        this.events = data.filter(el => moment(el.end).isAfter(moment(new Date())))
      }),
      this.getOwnStudentCompetenceProfile().then(data => {
        this.competenceProfile = data.content
      }).finally(() => {
        this.loading = false
      })
    ]).finally(() => {
      this.$root.$emit('end-load')
    })
  }

  applyKodeTest (): void {
    this.setApplyKodeTest().then(data => {
      this.activeStep = data.message
      this.loadData()
    })
  }

  changeProfileKodeStatus (): void {
    this.setProfileKodeStatus().then(data => {
      this.activeStep = data.message
      this.loadData()
    })
  }

  onBookmark (event: Event): void {
    let feedbackHeadline = ''
    let error = false
    this.$set(event, 'bookmark', !event.bookmark)
    this.bookmarkEvent(event).then(res => {
      feedbackHeadline = this.$t((event.bookmark ? 'events.bookmark.' : 'events.unbookmark.') + res.message).toString()
    }).catch((res) => {
      error = true
      feedbackHeadline = this.$t((event.bookmark ? 'events.bookmark.' : 'events.unbookmark.') + res.message).toString()
    }).finally(() => {
      this.$root.$emit('alert', feedbackHeadline, '', error)
    })
  }

  downloadPdf (fileType: string): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    const xhr = new XMLHttpRequest()
    xhr.open('GET', `${process.env.VUE_APP_ROOT_API}pdf/download-pdf/${fileType}`, true)
    if (localStorage.getItem('bearer')) {
      xhr.setRequestHeader('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('bearer') as string))
    }
    xhr.setRequestHeader('Content-type', 'application/octet-stream')
    xhr.responseType = 'arraybuffer'
    xhr.send()
    xhr.onload = function () {
      if (this.status === 200) {
        const blob = new Blob([this.response], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        if (xhr.getResponseHeader('Content-Disposition')) {
          link.download = xhr.getResponseHeader('Content-Disposition')!.split('filename=')[1]
        } else {
          link.download = 'download.pdf'
        }
        link.click()
        link.remove()
      } else {
        self.$root.$emit('alert', self.$i18n.t('warning.internal-error-message').toString(), self.$i18n.t('warning.general-error-message').toString(), true)
      }
    }
  }

  parseDateToOutputDate (date: Date): string | undefined {
    return parseDateToOutputDate(date, this.$i18n.locale)
  }
}
