export const NO_DATA = 'NO_DATA'
export const DATA = 'DATA'
export const APPLY = 'APPLY'
export const WAITING = 'WAITING'
export const FINISH = 'FINISH'

export default {
  NO_DATA,
  DATA,
  APPLY,
  WAITING,
  FINISH
}
