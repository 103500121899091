import { render, staticRenderFns } from "./KodeMetering.vue?vue&type=template&id=1ffa2c55&scoped=true&"
import script from "./KodeMetering.vue?vue&type=script&lang=ts&"
export * from "./KodeMetering.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ffa2c55",
  null
  
)

export default component.exports