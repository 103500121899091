











































































import Vue from 'vue'
import KodeStatus from '@/common/kodeStatus'
import { Component, Prop } from 'vue-property-decorator'
import { ProgressStep } from '@/store/types'
@Component({
  components: { }
})
/*
The progress steps component can be used to display a number of steps which guide the user through a certain process.
Every step could display another card/component or page.
 */
export default class ProgressSteps extends Vue {
  // unique identifier
  @Prop({ required: true })
  id: string

  // the steps for the progress (every step contains data for: index, status, text, icon, clickable)
  @Prop({ required: true })
  steps: ProgressStep[]

  // the active step
  @Prop({ required: true })
  active: string

  // if true the steps are numbered
  @Prop({ default: false })
  numbers: boolean

  // set the border radius of the step (0 - 50)
  @Prop({ default: 50, validator: function (value) { return value >= 0 && value <= 50 } })
  borderRadius: number

  // set the connector height between the steps (1 - 15)
  @Prop({ default: 7, validator: function (value) { return value >= 1 && value <= 15 } })
  connectorHeight: number

  // if every step can be clicked upon - can also be defined in steps individually
  @Prop({ default: false })
  clickable: boolean

  // a small version of the progress steps if there is not much space
  @Prop({ default: false })
  small: boolean

  // if the labels of the steps are shown
  @Prop({ default: true })
  showLabels: boolean

  // if the label should be placed in a tooltip
  @Prop({ default: false })
  labelAsTooltip: boolean

  // if the icons in the progress steps should be always shown (independent of the active status)
  @Prop({ default: false })
  alwaysShowIcons: boolean

  // prop to control the active state of the connector (default: the connector is active as far as the active step)
  @Prop({ default: undefined })
  activeTo: number | null

  // if steps can be deselected to NO_DATA by clicking on the active step
  @Prop({ default: true })
  deselectable: boolean

  get stepStyle (): string {
    return 'border-radius: ' + this.borderRadius + 'px;'
  }

  get connectorStyle (): string {
    return 'height: ' + this.connectorHeight + 'px;'
  }

  get activeIndex (): number {
    const foundStep = this.steps.find(step => step.status === this.active)
    return foundStep ? foundStep.index : -1
  }

  get connectorTo (): number {
    if (this.activeTo !== null) return this.activeTo
    else return this.activeIndex
  }

  getConnectorClass (index: number, left: boolean): string {
    let classes = ''
    if ((left && index === 0) || (!left && index === this.steps.length - 1)) classes += ' transparent'
    else if ((left && this.connectorTo >= index) || (!left && this.connectorTo > index)) classes += ' active'
    if (this.small) classes += ' small-connector'
    return classes
  }

  select (step: string): void {
    if (step === this.active && this.deselectable) step = KodeStatus.NO_DATA
    this.$emit('select', step)
  }
}
